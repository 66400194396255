import React from "react";
import { GuidedTour } from "../../components/GuidedTour";
import { PageLayout } from "../../components/PageLayout";
import { tourHomePageHeroImage, } from "../../images";

const GuidedTourPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${tourHomePageHeroImage})`}
      heroOverlayLeadElement="A Guided Tour"
      heroOverlaySecondaryElement="What does a modernized application look like?"
    >
      <GuidedTour />
    </PageLayout>
  );
};

export default GuidedTourPage;
